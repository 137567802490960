// eslint-disable-next-line
// @ts-ignore
import { PlaceholderCommon } from '@sitecore-jss/sitecore-jss-react/dist/esm/components/PlaceholderCommon';
import { Rendering } from '../Rendering';
import componentFactory from '~/temp/componentFactory';

export function usePlaceholder(key: string, rendering: Rendering<undefined>): JSX.Element[] {

	const placeholderData = PlaceholderCommon.getPlaceholderDataFromRenderingData(rendering, key);
	const placeholderCommonInstance = new PlaceholderCommon({ componentFactory: componentFactory });

	if (placeholderData) {
		return placeholderCommonInstance.getComponentsForRenderingData(placeholderData);
	}

	return [];
}