import React, { FC, useState } from "react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { CardContainerProps, CardProps } from "../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Splide, SplideProps, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { themable } from "~/foundation/Theme";
import { usePlaceholder } from "~/foundation/Jss/enhancers/usePlaceholder";
import { Splide as SplideItem } from "@splidejs/splide";
import { Box, useMediaQuery } from "@chakra-ui/react";
import { breakpoints } from "~/foundation/Theme/variables/breakpoints";

const CardContainer: FC<CardContainerProps> = ({ rendering, fields }) => {
	const { sitecoreContext } = useSitecoreContext();
	const placeholderComponents = usePlaceholder(rendering.placeholderNames.card_container, rendering);
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const [isMdBreakpointOrAbove] = useMediaQuery(`(min-width: ${breakpoints.md})`);
	const [isSlider, setIsSlider] = useState(false);

	const splideProps: SplideProps = {
		options: {
			lazyLoad: "nearby",
			pagination: false,
			snap: true,
			drag: isSlider,
			gap: "1.25rem",
			autoplay: false,
			type: "slide",
			flickPower: 120,
			speed: 800,
			easing: "cubic-bezier(0.25, 1, 0.5, 1)",
			fixedWidth: "calc( 25% - 15px )",
			fixedHeight: "auto",
			arrows: false,
			waitForTransition: isSlider,
			focus: 0,
			direction: isRtl ? "rtl" : "ltr",
			perPage: 1,
			mediaQuery: 'min',
			breakpoints: {
				700: {
					perPage: 2,
				}
			}
		}
	};

	const updateProgress = (splide: SplideItem, index: number) => {
		setIsSlider(splide.Components.Layout.isOverflow());
		const totalItems = splide.Components.Controller.getEnd();
		const progressBar = splide.root.querySelector("[data-progress-bar] span") as HTMLSpanElement;
		const rate = Math.min((index + 1) / (totalItems + 1), 1);
		progressBar.style.width = `${(100 * rate)}%`;
	}

	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]} overflow="hidden">
			<ContentWrapper py="0">
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper
				py="0"
				px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}
				sx={sitecoreContext.pageEditing ? { ".sc-jss-empty-placeholder": { width: "100%" } } : { ".splide__track": { overflow: "visible" } }}>
				<Splide
					tag="div"
					onResized={(splide) => updateProgress(splide, splide.Components.Controller.getIndex())}
					onMove={updateProgress}
					onMounted={(splide) => updateProgress(splide, 0)}
					hasTrack={false}
					{...splideProps}
					className={sitecoreContext.pageEditing ? "is-initialized" : ""}>
					<SplideTrack>
						{sitecoreContext.pageEditing
							? (
								<sc.Placeholder
									name={rendering.placeholderNames.card_container}
									rendering={rendering}
									renderEach={(component, index) => (
										<SplideSlide
											key={index}
											style={{
												minWidth: isMdBreakpointOrAbove ? "var(--chakra-sizes-cardWidth)" : "var(--chakra-sizes-cardWidthMobile)",
												maxWidth: "430px",
												width: "calc(25% - 15px)" }}>
											{component}
										</SplideSlide>
									)} />
							) : (
								<>
									{placeholderComponents.map((component, index) => {
										const cardTitle = (component.props as CardProps).fields?.title;

										return (
											<SplideSlide
												key={index}
												style={{
													minWidth: isMdBreakpointOrAbove ? "var(--chakra-sizes-cardWidth)" : "var(--chakra-sizes-cardWidthMobile)",
													maxWidth: "430px",
													width: "calc(25% - 15px)"
												}}
												data-tracking-type="visibility"
												data-tracking-id="manual_cards_listing"
												data-tracking-data={JSON.stringify({ text: cardTitle, index: index + 1, count: placeholderComponents.length })}>
												{component}
											</SplideSlide>
										)
									})}
								</>
							)
						}
					</SplideTrack>
					<Box
						display={isSlider ? "block" : "none"}
						data-progress-bar
						className="splide__progressbar"
						mt={["2rem", null, null, "2.65rem"]}
						w="100%"
						h="3px"
						borderRadius="2px"
						position="relative"
						bgColor="progressBar.default">
						<Box
							as="span"
							borderRadius="2px"
							h="100%"
							transitionDuration=".5s"
							transitionProperty="width"
							transitionTimingFunction="aramcoCubic"
							position="absolute"
							overflow="hidden">
							<Box
								w="100%"
								h="100%"
								borderRadius="2px"
								position="absolute"
								bg="gradients.brand"
							/>
						</Box>
					</Box>
				</Splide>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(CardContainer);